.background{
    background: rgb(82,113,255);
    background: linear-gradient(90deg, rgba(82,113,255,1) 0%, rgba(255,208,165,1) 100%);
    height: '100%';
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }



.text {
  font-size: 20px;
  padding-left: 20px;
  color: white;
  font-weight: 'bold';
  font-family: 'MainHeading';
  letter-spacing: 2px;
}


.name{
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* max-width: 100%; */
  color: orange;
  /* padding-right: 90px; */
  font-size: 50px;
  font-weight: 'bold';
  line-height: 60px;
  font-family: 'MainHeading';
}

.logo-name{
  color: white;
  /* padding-right: 90px; */
  font-size: 30px;
  /* font-weight: 'bold'; */
  font-family: 'MainHeading';
}


input[type=password], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 18px;
  resize: vertical;
}

input[type=text],  textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: vertical;
  /* margin-left: 10px; */
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: vertical;
}

select[type=textPopup] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: vertical;
}


input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.container_reg {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}

.butt {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -ms-transform: translate(-50%);
  background-color: #f1f1f1;
  color: black;
  font-size: 16px;
  padding: 16px 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.button {
  background-color: #ecf1ec; /* Green */
 display: flex;
  border: none;
  left: 50%;
  color: rgb(4, 4, 4);
  padding: 16px 32px;

  align-items: center;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 4px 2px 130px;
  cursor: pointer;
  border-radius: 18px;
}

.login-banner{display:flex; flex-direction:column; align-items:center}

@media only screen and (max-width: 700px) {
  .background{
    flex-direction: column;
  }
}

input[type=checkbox] {
  width: 20px;
  height: 20px;
  padding: 12px;
  line-height: 1;
  border: 1px solid #ccc;
  margin-top: 8px;
  margin-right: 8px;
  /* border-radius: 18px; */
  /* resize: vertical; */
}