html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.pageload{
    /* display: flex; */
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(82,113,255,1) 0%, rgba(255,208,165,1) 100%);
}

.tbody, td, tfoot, th, thead, tr{
    border-color: transparent !important;
    border-style: hidden !important;
    border-width: 0 !important;
    width: 400px !important;
    /* font-size: 25px !important; */
    margin-left: 20px;
}
