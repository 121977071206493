.bg-service{
    background: rgb(82,113,255);
    background: linear-gradient(90deg, rgb(94 120 239) 0%, rgba(255,208,165,1) 100%);
    height: 100%;
}
.MainContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.service_heading{
    font-size: 50px;
    color: white;
    font-family: 'Mainheading';
}

.leftViewHolder{
    width: 50%;
}

.rightViewHolder{
    width: 50%;
    margin-left: 30px;
}

.seperator{
    width: 2px;
    height: 550px;
    background-color: white;
    /* margin-top: 80px; */
}

.listitems{
    font-size: 20px;
    color: white;
    font-family: 'listItems';
}

.heading{
    font-size: 25px;
    color: white;
    font-family: 'Mainheading';
    margin-top: 50px;
}

@media only screen and (max-width: 700px) {
    .MainContainer{
        flex-direction: column;
    }

    .service_heading{
        font-size: 27px;
        color: white;
        font-family: 'Mainheading';
    }

    .leftViewHolder{
        width: 100%;
    }
    
    .rightViewHolder{
        margin-top: 0px;
        width: 100%;
        margin-left: 0px;
    }

    .seperator{
        width: 250px;
        height: 2px;
        background-color: white;
        margin-top: 10px;
    }

    .listitems{
        font-size: 20px;
        color: white;
        font-family: 'listItems';
    }

    .heading{
        font-size: 22px;
        margin-top: 10px;
    }
}