.App {
  background: rgb(82,113,255);
  background: linear-gradient(90deg, rgba(82,113,255,1) 0%, rgba(255,208,165,1) 100%);
  z-index: -1;
}

#root, .pageload {
  height: 100%;
  width: 100%;
}

.SideNavcontainer{
  display:flex;
  margin: 0px;
  padding: 0px;
  position: fixed;
  z-index: 99;
  height: '100%' ;
  overflow: hidden;
}
main{
  width: 100%;
  /* padding: 20px; */
}
.sidebar{
  background: #5271FF;
  color: #fff;
  height: 250vh;
  width: 250px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
  flex-direction: row;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link{
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover{
  background:rgb(252, 168, 111);
  color: #5271FF;
  transition: all 0.5s;
}
.active{
  background: rgb(252, 168, 111);
  color: #5271FF;
  text-decoration: none;
}
.icon, .link_text{
  font-size: 20px;
  text-decoration: none;
}

