.background-Tc{
    background: rgb(82,113,255);
    background: linear-gradient(90deg, rgba(82,113,255,1) 0%, rgba(255,208,165,1) 100%);
    /* height: '100%'; */
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-evenly; */
  }
.header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}

.header-detail-font{
    color: black;
    font-family: 'MainHeading';
    font-size: 25px;
    font-weight: bolder;
    text-align: left;
}

.header-amount-font{
    color: black;
    font-family: 'MainHeading';
    font-size: 40px;
    font-weight: bolder;
}
.tc-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: flex-start; */
    flex-wrap: wrap;
    margin-left: 20px;
}

.tc-heading{
    color: orange;
    font-weight: bolder;
}

.tc-checkbox{
    display: flex;
    flex-direction: column;
}

.checkbox{
    color: white;
}

.imp-note-container{
   margin-top: 10px;
}

.proceed-pay{
 color: white;
 background-color: rgb(4, 137, 4);
 width: 340px;
 height: 60px;
 border-radius: 8px;
 font-size: 20px;
 align-self: center;
}

.checkbox-hearby{
    color: white;
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 700px) {
    .header-container{
        flex-direction: column;
        justify-content: space-between;
    }
}