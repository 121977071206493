@font-face {
  font-family: "Poppins";
  src: local("MainHeading"),
       url("./assets/fonts/Poppins-ExtraBold.ttf") format("woff"),
}

@font-face {
  font-family: "Poppins";
  src: local("listItems"),
       url("./assets/fonts/Poppins-SemiBold.ttf") format("woff"),
}
