.card-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align the popup to the top */
    z-index: 9999; /* Ensure popup is above other content */
    overflow-y: auto; /* Make the screen scrollable */
    padding: 50px 0; /* Add padding to prevent content from being hidden under browser UI */
  }
  
  .card-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 800px; /* Adjust max-width as needed */
  }

  
  .card-title {
    margin-bottom: 6px;
  }
  
  .table-container {
    margin-top: 10px;
  }
  
  .card-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 6px;
    border-bottom: 1px solid #ddd;
  }
  
  .th {
    background-color: #f2f2f2;
  }
  
  .terms-container {
    margin-top: 10px;
  }
  
  .terms-container h4 {
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .btn-container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .close-btn, .download-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .close-btn {
    background-color: #ff6347;
    color: white;
  }
  
  .download-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .close-btn:hover, .download-btn:hover {
    opacity: 0.8;
  }
  