.Calender_heading{
    font-size: 60px;
    color: white;
    font-family: 'MainHeading';
}

.Calender_heading-black{
    font-size: 60px;
    color: rgb(0, 0, 0);
    font-family: 'MainHeading';
    margin-left: 60px;
}

.headingContainer{
 margin-top: 60px;
}

.viewholder{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.calenderContainer{
    padding-bottom: 60px;
}

.react-calendar {
    height: 500px !important; 
    width: 500px !important;
    border-radius: 8px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    line-height: 4em;
}

.react-calendar__month-view__days{
    line-height: 3.5em;
}

.react-calendar__tile {
    line-height: 3.5 !important;
}

.map{
    display: flex;
    height: 200px;
    width: 400px;
    object-fit: cover;
    margin-right: 30px;
    align-self: center;
    margin-top: 125px;
}

.calender_rightViewHolder{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px
}

.roomTypeContainer{
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.dropdown{
    margin-bottom: 10px;
    width: auto;
    margin-top: 10px;
}

.roomType{
    display: flex;
    background-color: white;
    height: 250px;
    width: 400px;
    border-radius: 8px;
    margin-top: 85px;
}

.bookNow{
    width: 340px;
    height: 60px;
    background-color: #FF914D;
    color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    border-color: #FF914D;
}

.highlight{
  background-color:yellow;
  pointer-events: none;
}

.highlight-gr{
    background-color:rgb(5, 186, 5) !important;
    pointer-events: none;
  }

  .highlight-rd{
    background-color:red !important;
    pointer-events: none;
    color: white !important;
  }
  
/* uncomment here for weekend date color */
/* .react-calendar__month-view__days__day--weekend {
    color: #000000 !important;
} */ 

.radioGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.contactDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Mainheading';
    font-size: 20px;
    color: rgb(0, 0, 0);
  }

.manager{
    margin-right: 700px;
}

.container-*{
    padding-left: 0px !important;
}

.container_seprator{
    display: flex;
    width: 100%;
    height: 2px;
    background-color: white;
}
  /*----------------------------------------------------------------------*/

  @media only screen and (max-width:912px) {

    .viewholder{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .seperator{
        width: 400px;
        height: 1px;
        align-self: center;
        margin-bottom: 40px;
      }

    .map{
        height: 200px;
        width: 490px;
        margin-top: 35px;
    }

    .contactDetails{
        justify-content: space-between;
    }

    .manager{
        margin-right: 30px;
    }
  }

  @media only screen and (max-width: 700px) {
    .headingContainer{
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
    }

    .Calender_heading{
        font-size: 20px;
    }

    .Calender_heading-black{
        font-size: 20px;
    }

    .viewholder{
        flex-direction: column;
    }

    .react-calendar {
        height: 350px !important; 
        width: 280px !important;
        margin-right: 30px;
    }

    .react-calendar__month-view__days{
        line-height: 1.5em;
    }
    
    .react-calendar__tile {
        line-height: 1.5 !important;
    }

    .seperator{
        width: 200px;
        height: 1px;
        align-self: center;
        margin-right: 30px;
    }

    .calender_rightViewHolder{
        height: 400px;
        padding-bottom: 40px;
    }

    .map{
        height: 100px;
        width: 270px;
        margin-top: 25px;
    }

    .roomType{
        width: 270px;
        margin-top: 35px;
    }

    .bookNow{
        width: 220px;
    }

    .contactDetails{
        flex-direction: column;
        justify-content: space-evenly;
    }

    .manager{
        margin-right: 0px;
    }
  }

  @media only screen and (max-width: 370px) {
    .react-calendar {
        height: 350px !important; 
        width: 250px !important;
    }
  }

 .react-calendar__tile:disabled {
    background-color:transparent;
    color: rgb(0, 0, 0);
 }

 .react-calendar__tile:hover .tooltip {
    visibility: visible !important;
  }

 .greenhigh{
    color: green;
 }