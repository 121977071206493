.tranaction-details-ad{
    color: black;
   }

.cancle-book{
    width: 200px;
    height: 40px;
    background-color: red;
    color: white;
    border-radius: 8px;
    margin-bottom: 10px;
    border-color: red;
}

.bookNow-ad{
    width: 200px;
    height: 40px;
    background-color: #FF914D;
    color: white;
    border-radius: 8px;
    margin-bottom: 10px;
    border-color: #FF914D;
}

.transaction-containerlist-ad{
    display: flex;
    flex-direction: row;
    line-height: 5px;
    justify-content: space-around;
    margin-top: 15px;
}

.horizontal-seprator-ad{
    width: 100%;
    height: 1px;
    background-color: black;
    margin-top: 10px;
    margin-bottom: 10px;
}

.transaction-List-ad{
    height:70%;
    overflow:auto
}

.searchbar{ display: flex; flex-direction: row }

@media only screen and (max-width: 700px) {
    .transaction-containerlist-ad{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .searchbar{ display: flex; flex-direction: column-reverse }
}

