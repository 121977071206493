.background-profile{
    background: rgb(82,113,255);
    background: linear-gradient(90deg, rgba(82,113,255,1) 0%, rgba(255,208,165,1) 100%);
    /* height: '100%'; */
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-evenly; */
    overflow: hidden;
  }

.profile-heading {
    font-size: 60px;
    color: white;
    font-family: 'MainHeading';
}

.profile-primary-container{
    display: flex;
    flex-direction: row;
}

.profile-details-container{
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 20px;
    height: auto;
}

.profile-details{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: white;
    font-family: 'listItems';
    font-size: 18px;
}

.seperator-profile{
    width: 2px;
    height: 600px;
    background-color: white;
    margin-left: 40px;
}

.transaction-container{
 display: flex;
 margin-left: 20px;
 margin-right: 20px;
 width: 60%;
 flex-direction: column;
 height: 90%;
}

.transaction-heading{
    font-family: 'MainHeding';
    font-size: 30px;
    color: white;
}

.transaction-containerlist{
    display: flex;
    flex-direction: row;
    line-height: 5px;
    justify-content: space-between;
    margin-top: 15px;
}

.tranaction-details{
 color: white;
}

.horizontal-seprator{
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.status-container{
    display:flex;
    margin-right:10px;
    justify-content:space-between;
    flex-direction:column;
    align-items:flex-start;
    color:white; 
    width:220px;
}

.transaction-List{
    height:40%;
    overflow:auto
}

@media only screen and (max-width: 700px) {
    .background-profile{
        overflow: auto;
    }
    .profile-primary-container{
        flex-direction: column;
    }

    .seperator-profile{
        width: 100%;
        height: 1px;
        align-self: center;
        margin-bottom: 40px;
    }
    .transaction-container{
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
        flex-direction: column;
       }
    .transaction-containerlist{
        display: flex;
        flex-direction: row;
        line-height: 5px;
        justify-content: space-between;
        margin-top: 15px;
    }
  }

  